import { Colors } from 'theme/variables';


export default `
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1000;
  background-color: ${Colors.background};
`;
